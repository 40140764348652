<template>
  <div class="card">
    <div class="card-body">
      <div class="product-container">
        <h3>Информация о товаре</h3>
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Название">
                <b-form-input
                  v-model="product.name"
                  placeholder="Название товара"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Отображаемое название">
                <b-form-input
                  v-model="product.shopDisplayName"
                  placeholder="Отображаемое название"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Категории">
                <v-select
                  v-model="product.category"
                  class="mt-5 custom"
                  multiple
                  :options="allCategories"
                  label="title"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Количество">
                <b-form-input
                  v-model="product.quantity"
                  placeholder="Количество"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Бренд">
                <b-form-input
                  v-model="product.brand"
                  placeholder="Бренд"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Модель">
                <b-form-input
                  v-model="product.model"
                  placeholder="Модель"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Код">
                <b-form-input
                  v-model="product.code"
                  placeholder="Код"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-for="code in product.codeList"
              :key="code.id"
              md="6"
            >
              <b-form-group :label="`Штрихкод ${code.code_type}`">
                <b-form-input
                  v-model="code.code"
                  placeholder="Штрихкод"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Цена">
                <b-form-input
                  v-model="product.price"
                  placeholder="Цена"
                  no-wheel
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Закупочная цена">
                <b-form-input
                  v-model="product.purchasePrice"
                  placeholder="Закупочная цена"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Цена со скидкой">
                <b-form-input
                  v-model.number="product.discountPrice"
                  placeholder="Цена со скидкой"
                  @input="updatePercentageFromDiscountPrice"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Процент скидки %">
                <b-form-input
                  v-model.number="discount"
                  placeholder="%"
                  no-wheel
                  @input="updateDiscountPriceFromPercentage"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Скрыть товар">
                <b-form-checkbox
                  v-model="product.isHidden"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Описание товара">
                <quill-editor v-model="product.description" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <h3>Фото</h3>
        <div>
          <file-multi-uploader
            :file-list="imageFileList"
            v-model="imagesIdList"
          />
        </div>
        <h3>Опции товара</h3>
        <b-row
          v-for="(item, index) in product.options"
          :key="item.id"
        >
          <b-col md="6">
            <b-form-group label="Тип">
              <v-select
                v-model="item.option"
                class="mt-5"
                :options="allOptionTypes"
                label="name"
                @input="newType => changeOptionType(index, newType)"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Значение">
              <v-select
                v-model="item.value"
                class="mt-5"
                :options="item.allValues"
                label="name"
                @input="newType => changeOptionValue(index, newType)"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="1"
            class="remove-icon"
          >
            <feather-icon
              icon="TrashIcon"
              style="width: 20px; height: 20px"
              @click="() => removeOption(index)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="addProductOption"
            >
              {{ 'Добавить опцию' }}
            </b-button>
          </b-col>
        </b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 save-button"
            @click="saveItem"
          >
            {{ 'Сохранить' }}
          </b-button>
        </b-col>
        <b-modal
          v-if="modal.isOpen"
          id="modal-primary"
          v-model="modal.isOpen"
          ok-only
          ok-title="Отмена"
          modal-class="modal-primary"
          centered
          title="Новая опция/значение"
        >
          <div>{{ modal.text }}</div>
          <b-form-input
            v-model="modal.newOptionData"
            placeholder="Новая опция или значение"
          />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-1"
            variant="primary"
            @click="addNewOptionData"
          >
            Добавить
          </b-button>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BButtonClose,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import crud_module from '../../libs/crud_module'
import FileUploader from '@/global-components/FileUploader'
import FileMultiUploader from '@/global-components/FileMultiUploader.vue'

const product_option_module = crud_module({
  entity_name: 'product-option',
})

const product_option_value_module = crud_module({
  entity_name: 'product-option/value',
})

const product_module = crud_module({
  entity_name: 'product',
})

export default {
  name: 'ProductInfo',
  components: {
    FileMultiUploader,
    BFormCheckbox,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BModal,
    BButtonClose,
    'v-select': vSelect,
    quillEditor,
    FileUploader,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      product: {
        id: null,
        name: '',
        shopDisplayName: '',
        description: '',
        category: [],
        purchasePrice: '',
        discountPrice: '',
        price: '',
        codeList: [],
        code: '',
        model: '',
        brand: '',
        quantity: 0,
        options: [],
        isHidden: false,
      },
      images: [],
      imagesIdList: [],
      newImages: [],
      allCategories: [],
      allOptionTypes: [],
      discount: 0,
      modal: {
        isOpen: false,
        text: '',
        type: '',
        newOptionData: '',
        optionIndex: null,
      },
    }
  },
  computed: {
    categories() {
      return this.$store.state.shop.categories
    },
    imageFileList(){
      return this.images.map(image => image.file)
    }
  },
  watch: {
    categories() {
      this.parseCategories(this.categories)
    }
  },
  async created() {
    try {
      const response = await product_module.fetchItem(
        `${this.$route.params.id}`,
      )
      await this.updateProduct(response.data.product)
      this.getAllOptions()
      this.$store.dispatch('shop/fetchCategories')
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    parseCategories(data) {
      data.forEach(category => {
        this.allCategories.push(category)
        if (category.children.length !== 0) {
          this.parseCategories(category.children)
        }
      })
    },
    async addNewOptionData() {
      if (this.modal.type === 'type') {
        await product_option_module
          .createItem({ name: this.modal.newOptionData })
          .then(response => {
            this.modal.isOpen = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Опция добавлена',
                variant: 'success',
              },
            })
            this.getAllOptions()
            return response
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } else {
        const payload = {
          name: this.modal.newOptionData,
          product_option_uuid:
          this.product.options[this.modal.optionIndex].option.uuid,
        }
        await product_option_value_module
          .createItem(payload)
          .then(async response => {
            this.modal.isOpen = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Значение опции добавлено',
                variant: 'success',
              },
            })
            const optionValues = await this.getOptionValues(
              payload.product_option_uuid,
            )
            this.product.options[this.modal.optionIndex].value = {}
            this.product.options[this.modal.optionIndex].allValues = [
              {
                name: 'Добавить новое значение',
                create: true,
              },
              ...optionValues,
            ]
            return response
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      }
    },
    updatePercentageFromDiscountPrice() {
      this.discount = Math.round(Math.max(0, ((this.product.price - this.product.discountPrice) / this.product.price) * 100))
    },
    updateDiscountPriceFromPercentage() {
      this.product.discountPrice = this.product.price - (this.product.price * Math.min(Math.max(this.discount, 0), 100)) / 100
    },
    async changeOptionType(index, selectedOption) {
      if (selectedOption.create) {
        this.modal.isOpen = true
        this.modal.text = 'Введите название новой опции'
        this.modal.type = 'type'
      } else {
        const optionValues = await this.getOptionValues(selectedOption.uuid)
        this.product.options[index].value = {}
        this.product.options[index].allValues = [
          {
            name: 'Добавить новое значение',
            create: true,
          },
          ...optionValues,
        ]
      }
    },
    changeOptionValue(index, selectedValue) {
      if (selectedValue.create) {
        this.modal.isOpen = true
        this.modal.text = 'Введите название нового значения'
        this.modal.type = 'value'
        this.modal.optionIndex = index
      }
    },
    async updateProduct(value) {
      this.product.id = value.id
      this.product.name = value.name
      this.product.shopDisplayName = value.shopDisplayName
      this.product.description = value.description
      this.product.purchasePrice = Math.trunc(value.purchasePrice / 100)
      this.product.price = Math.trunc(value.price / 100)
      this.product.discountPrice = Math.trunc(value.discountPrice / 100)
      this.product.quantity = value.quantity
      this.product.codeList = value.codeList
      this.product.code = value.code
      this.product.model = value.model
      this.product.brand = value.brand
      this.product.isHidden = value.isHidden
      this.images = value.images || []
      if (value.options.length !== 0) {
        this.product.options = []
        for (const option of value.options) {
          const optionValues = await this.getOptionValues(option.option_id)
          this.product.options.push({
            ...option,
            allValues: [
              {
                name: 'Добавить новое значение',
                create: true,
              },
              ...optionValues,
            ],
          })
        }
      }
      if (value.categories.length !== 0) {
        this.product.category = value.categories.map(category => category)
      } else {
        this.product.category = []
      }
    },
    async getOptionValues(optionId) {
      const optionValues = await product_option_module
        .fetchItem(optionId, { query: { admin: '1' } })
        .then(res => res.data.values)
      return optionValues
    },
    removeOption(index) {
      this.product.options.splice(index, 1)
    },
    addProductOption() {
      const newOption = {
        id: this.product.options.length + 1,
        option: {},
        value: {},
      }
      this.product.options.push(newOption)
    },
    async getAllOptions() {
      const payload = {
        skipCount: 0,
        maxResultCount: 10000,
      }
      await product_option_module.fetchItems(payload).then(async res => {
        this.allOptionTypes = [
          {
            name: 'Добавить новую опцию',
            create: true,
          },
          ...res.data.rows,
        ]
      })
    },
    async saveItem() {
      const dataForUpdate = {
        product: {
          id: this.product.id,
          name: this.product.name,
          shopDisplayName: this.product.shopDisplayName,
          code: this.product.code,
          description: this.product.description,
          brand: this.product.brand,
          model: this.product.model,
          price: this.product.price * 100,
          purchasePrice: this.product.purchasePrice * 100,
          discountPrice: +this.product.discountPrice * 100,
          isHidden: this.product.isHidden,
          quantity: this.product.quantity,
          category_id: this.product.category.map(e => e.id),
          codeList: this.product.codeList.map(code => ({
            code_type: code.code_type,
            code: code.code,
          })),
        },
        images: this.imagesIdList,
        options: this.product.options.map(option => ({
          option_id: option.option.uuid,
          value_id: option.value.uuid,
        })),
      }
      await product_module
        .updateItem(dataForUpdate.product.id, dataForUpdate)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'BellIcon',
              text: 'Товар изменен',
              variant: 'success',
            },
          })
          this.$emit('closeModal', false)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'BellIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-container {
  padding: 20px 15px 15px;
}

.save-button {
  float: right;
  margin-right: 0;
}

.remove-icon {
  align-self: center;
  cursor: pointer;
}

.upload-wrap {
  margin: 15px 0;
}

.custom {
  ::v-deep span {
    font-size: 12px;
    padding: 0 0.2em;
  }
}

::v-deep .v-select #vs1__combobox {
  height: auto;
  min-height: 38px;
}

.image-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;

  .image-container {
    position: relative;
    width: 150px;
    height: 200px;
    padding: 20px 3px 3px;
    border: 1px solid #5e50ee;
    border-radius: 7px;

    .close-button {
      position: absolute;
      top: -3px;
      right: 3px;
    }

    .image {
      width: 100%;
      height: 100%;
      border-radius: 7px;
      border: 1px solid #a4a4a4;
    }
  }
}
</style>
<style lang="scss">
.v-select {
  margin-top: 0 !important;

  #vs1__combobox {
    height: 38px;
  }
}
</style>
